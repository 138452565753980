import React from 'react';
import styled from 'styled-components';
import ReactPlayer from 'react-player';
import DetailInput from '../others/DetailInput';
import DefaulFooter from '../../views/DefaulFooter';



const Container = styled.div`
    width: 100%;
    min-height: 100vh;
    box-sizing: border-box;
`
const HeaderContainer = styled.div`
    width: 100%;
    color: white;
    background-color: #f3f3f3;
    background-image: url("../images/education.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    display: flex;
    @media only screen and (max-width:1024px){
        flex-direction:column;
    }
`
const HeaderDetails = styled.div`
    width: 50%;
    padding: 120px;
    box-sizing: border-box;
    background-color: #0000009c;
    @media only screen and (max-width:1024px){
        width: 100%;
        padding: 50px;
    }
`
const HeaderImage = styled.div`
    width: 50%;
    padding: 120px 0px 0px 0px;
    box-sizing: border-box;
    background-color: #0000009c;
    overflow: hidden;
    min-height: 600px;
    @media only screen and (max-width:1024px){
        width: 100vw;
        padding: 0px;
        min-height: 0px;
    }
`
const Monitor = styled.div`
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("../images/SHA3.jpg");
    background-position: top;
    background-position: right;
    @media only screen and (max-width:1024px){
        width: 100%;
        background-position: left;
        background-position: bottom;
        height: 250px;
        padding: 0px;
    }
`

const HeaderTitle = styled.div`
    width: 100%;
    color: white;
    font-size: 50px;
    font-weight: bold;
`
const HeaderDescription = styled.div`
    padding-top: 40px;
    width: 100%;
    color: white;
    font-size: 20px;
    font-weight: bold;
    text-align: justify;
`
const CloseContainer = styled.div`
    padding: 10px 20px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    position: absolute;
`
const CloseButton = styled.a`
    //width: 40px;
    //height: 40px;
    padding: 5px 10px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: #ffffff;
    border-radius: 60px;
    cursor: pointer;
    border: 1px solid #ffffff;
    text-decoration: none;
`
const Logo = styled.div`
    font-size: 20px;
    color: #ffffff;
    padding-right: 10px;
`
const HomeButton = styled.div`
    font-size: 20px;
    color: #ffffff;
    padding-right: 10px;
    font-weight: bold;
`
const WorkArea = styled.div`
    @media only screen and (max-width:768px){
        flex-direction: column;
    }
`
const StepContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    @media only screen and (max-width:768px){
        flex-direction: column;
        padding: 20px;
        box-sizing: border-box;
    }
`
const StepContainer2 = styled.div`
    width: 100%;
    display: flex;
    background-color: #f3f3f3;
    align-items: center;
    @media only screen and (max-width:768px){
        flex-direction: column-reverse;
        padding: 20px;
        box-sizing: border-box;
    }
`
const StepBox = styled.div`
    width: 50%;
    margin: 50px;
    box-sizing: border-box;
    @media only screen and (max-width:768px){
        width: 100%; 
    }
    
`
const StepTitle = styled.div`
    font-size: 25px;
    font-weight: bold;
    color: #424242;
`
const StepDetails = styled.div`
    padding-top: 40px;
    width: 100%;
`
const StepVideo = styled.div`
    
`
const Emp = styled.span`
    color: #3884F5;
    font-weight: 550;
`
const Image = styled.img`
    width: 100%;
    border: 1px gainsboro solid;
`
function AcademicProjects() {
    return (
        <Container >
            <HeaderContainer>
                <CloseContainer>
                    <CloseButton href="javascript:history.back()" >
                        <Logo className='fas fa-arrow-left' />
                        <HomeButton>
                            HOME
                        </HomeButton>
                    </CloseButton>
                </CloseContainer>
                <HeaderDetails>
                    <HeaderTitle>
                        Academic Project: Automated Hydroponic System
                    </HeaderTitle>
                    <HeaderDescription>
                        “The present integrative project according to the tenth four-month period is carried out based on the agronomical environment, by designing and implementing a hydroponic cultivation system, incorporating specific modifications to make it autonomous.”
                    </HeaderDescription>
                </HeaderDetails>
                <HeaderImage>
                    <Monitor />
                </HeaderImage>
            </HeaderContainer>
            <WorkArea>
                <StepContainer>
                    <StepBox>
                        <StepTitle>
                            Automated Hydroponic System
                        </StepTitle>

                    </StepBox>
                    <StepBox>
                        <StepDetails>
                            <Image src='images/SHA2.jpg' />    
                        </StepDetails>
                    </StepBox>
                </StepContainer>

                <StepContainer2>
                    <StepBox>
                        <StepDetails>
                            <Image src='images/SHA4.png' />  
                        </StepDetails>
                        <StepVideo>
                                <ReactPlayer
                                    url='images/SHA1.mp4'
                                    controls
                                    loop
                                    playing
                                    muted
                                    width='100%'
                                    height='100%'
                                />
                            </StepVideo>
                    </StepBox>
                    <StepBox>
                        <StepTitle>
                            Mechanical Design
                        </StepTitle>
                        <StepDetails>
                            <Emp>Solidworks  Design</Emp>
                        </StepDetails>
                    </StepBox>
                </StepContainer2>

                <StepContainer>
                    <StepBox>
                        <StepTitle>
                            Control
                        </StepTitle>
                        <StepDetails>
                            <Emp>ESP32 microcontroller </Emp>
                        </StepDetails>
                        <StepDetails>
                            The following subsystems complement, interact and unite to achieve total control of the application.
                        </StepDetails>
                        <StepDetails>
                            <DetailInput topic='Remote Monitoring System' />
                            <DetailInput topic="Water Pumping" />
                            <DetailInput topic="Water Blending" />
                            <DetailInput topic="High and Low PH Dispensing" />
                            <DetailInput topic='Nutrient Mixing and Dispensing' />
                            <DetailInput topic='Water Level Control Systems' />
                            <DetailInput topic='Water Oxygenation System' />
                            <DetailInput topic='Temperature Control System' />
                        </StepDetails>
                    </StepBox>
                    <StepBox>
                        <StepDetails>
                            <Image src='images/SHA5.png' /> 
                        </StepDetails>
                    </StepBox>
                </StepContainer>

                <StepContainer2>
                    <StepBox>
                        <StepDetails>
                            <Image src='images/SHA6.png' /> 
                            <Image src='images/SHA7.png' /> 
                        </StepDetails>
                    </StepBox>
                    <StepBox>
                        <StepTitle>
                            Remote Monitoring
                        </StepTitle>
                    </StepBox>
                </StepContainer2>

                <StepContainer>
                    <StepBox>
                        <StepTitle>
                            Human-Machine Interface
                        </StepTitle>
                        <StepDetails>
                            We present the control interface of the <Emp>“Nextion”</Emp>  display, which was selected due to its <Emp> compatibility, price, design and quality,</Emp> as well as the fact that it has several tabs for different monitoring and alerts, as well as connecting to the main <Emp>Wi-Fi </Emp> network.
                        </StepDetails>
                    </StepBox>
                    <StepBox>
                        <StepDetails>
                            <Image src='images/SHA8.png' /> 
                        </StepDetails>
                    </StepBox>
                </StepContainer>

        

            </WorkArea>
            <DefaulFooter />
        </Container>
    );
}

export default AcademicProjects;