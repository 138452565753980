import React from 'react';
import styled from 'styled-components';
import DefaultHeader from '../components/headers/DefaultHeader';
import Introduction from '../components/others/Introduction';
import Work from '../components/others/Work';


const Container = styled.div`
    width: 100%;
    min-height: 100vh;
`
const ContainerBox = styled.div`
    width: 100%;
    min-height: 100vh;
    padding: 0px 25px 25px 110px;
    box-sizing: border-box;
    position: relative;
    @media only screen and (max-width:1024px){
        padding: 0px 50px 50px 50px;
    }
    @media only screen and (max-width:768px){
        padding: 0px 10px 50px 10px;
    }

`
const Glass = styled.div`
    width: 100%;
    height: 100%;
    background-color: white;
    opacity: .90;
    position: absolute;
`
const WorkArea = styled.div`
    width: calc(100% - 0px);
    height: calc(100vh - 164px);
    display: flex;
    justify-content: center;
    align-items: center;
    
    @media only screen and (max-width:1024px){
        width: 100%;
        height: 100%;
        flex-direction: column;
        justify-content: start;
    }
    @media only screen and (max-width:768px){
        width: 100%;
        
    }
`
const Section = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    @media only screen and (max-width:1024px){
        flex-direction: column;
        justify-content: start;
    }
`


function Resume() {
    return (
        <Container>
            <Glass />
            <ContainerBox>
                <DefaultHeader title="WORK EXPERIENCE" />
                <Introduction
                    text=""
                />
                <WorkArea>
                    <Section>
                        <div>
                            <Work
                                title="Controls Engineer II "
                                company=" Corning Optical Communication "
                                date="Today"
                                activities={
                                    <ul>
                                        <li>Generate and guide control team actions that directly impact OEE metrics.</li>
                                        <li>Participate in the planning, design and implementation of automation solutions.</li>
                                        <li>Control system data analysis.</li>
                                        <li>Generation of algorithms for solving complex problems in the plant.</li>
                                        <li>Generation of multipurpose tools making use of advanced skills in software development.</li>
                                        <li>Design and Integration of control systems.</li>
                                        <li>Design and integration of mechanical systems.</li>
                                        <li>Administration and project management.</li>
                                        <li>Equipment troubleshooting.</li>
                                        <li>Engineers and technicians Training.</li>
                                        <li>Analysis of new technologies for the subsequent training of the control team.</li>
                                    </ul>
                                }
                            />
                            <Work
                                title="Controls Engineer I "
                                company=" Corning Optical Communication "
                                date="May 2021 – April 2022"
                                activities={
                                    <ul>
                                        <li>Equipment troubleshooting.</li>
                                        <li>Participate in implementations of automation solutions.</li>
                                        <li>Integration of control systems.</li>
                                        <li>Design and integration of mechanical systems.</li>
                                        <li>Control system data analysis.</li>
                                        <li>Engineers and technicians Training</li>
                                        <li>Analysis of new technologies for the subsequent training of the control team.</li>
                                    </ul>
                                }
                            />


                        </div>
                        <Work
                            title="Industrial Machine/Tool Designer"
                            company="J&J Maquinados  "
                            date=" April 2019 – December 2019"
                            activities={
                                <ul>
                                    <li>Product analysis.</li>
                                    <li>Develop machine designs (3D and 2D).</li>
                                    <li>Prepare bill of materials (BOM).</li>
                                    <li>Follow-up on project development.</li>
                                </ul>
                            }
                        />
                    </Section>
                </WorkArea>
            </ContainerBox>
        </Container>
    );
}

export default Resume;