import React from 'react';
import styled from 'styled-components';


const Container = styled.div`
    width: 100%;
    font-size: 35px;
    font-weight: bold;
    color: #424242;
    padding: 40px 0 20px 0;
    box-sizing: border-box;
`
const Title = styled.div`
    width: 100%;
    text-align: center;
    padding: 5px;
    box-sizing: border-box;
`
const TitleFooter = styled.div`
    width: 100%;
    
`
const LongLine = styled.div`
    width: 120px;
    height: 1px;
    background-color: #d0cece;
    margin: auto;
`
const ShortLine = styled.div`
    width: 40px;
    height: 3px;
    background-color: #3884F5;
    margin: auto;
    margin-top: -2px;
`

function DefaultHeader(props) {
    return (
        <Container >
            <Title>
                {props.title}
            </Title>
            <TitleFooter>
                <LongLine />
                <ShortLine />
            </TitleFooter>
        </Container>
    );
}

export default DefaultHeader;