import React from 'react';
import styled from 'styled-components';


const Container = styled.div`
    width: 100%;
    color: #424242;
    font-weight: bold;
    padding: 10px;
    box-sizing: border-box;
    border-top: 1px solid #EAEAEA;
    border-bottom: 1px solid #EAEAEA;
`
const Text = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
`
const Details = styled.div`
    width: 100%;
    font-size: 10px;
    color: #5f6d83;
`


function Skill(props) {
    return (
        <Container >
            <Text>
                {props.text}
            </Text>
            <Details>
                {props.details}
            </Details>
        </Container>
    );
}

export default Skill;