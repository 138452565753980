import React from 'react';
import styled from 'styled-components';


const Container = styled.div`
    width: 100%;
    padding: 10px 0;
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;
`
const Box = styled.div`
    display: flex;
    align-items: center;
`
const Topic = styled.div`
    color: #424242;
    font-weight: bold;
    padding-right: 10px;
    @media only screen and (max-width:1024px){
        //width: 70px;
    }
`
const Text = styled.div`
`
const Icon = styled.div`
    color:#3884F5;
    padding-right: 10px;
`



function DetailInput(props) {
    return (
        <Container >
            <Box>
                <Icon className='fas fa-chevron-right' />
                <Topic>
                    {props.topic}
                </Topic>
            </Box>

            <Text>
                {props.text}
            </Text>

        </Container>
    );
}

export default DetailInput;