import React from 'react';
import styled from 'styled-components';
import ReactPlayer from 'react-player';
import DetailInput from '../others/DetailInput';
import DefaulFooter from '../../views/DefaulFooter';



const Container = styled.div`
    width: 100%;
    min-height: 100vh;
    box-sizing: border-box;
`
const HeaderContainer = styled.div`
    width: 100%;
    color: white;
    background-color: #f3f3f3;
    background-image: url("../images/world.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    display: flex;
    @media only screen and (max-width:1024px){
        flex-direction:column;
    }
`
const HeaderDetails = styled.div`
    width: 50%;
    padding: 120px;
    box-sizing: border-box;
    background-color: #0000009c;
    @media only screen and (max-width:1024px){
        width: 100%;
        padding: 50px;
    }
`
const HeaderImage = styled.div`
    width: 50%;
    padding: 120px 0px 0px 0px;
    box-sizing: border-box;
    background-color: #0000009c;
    overflow: hidden;
    min-height: 600px;
    @media only screen and (max-width:1024px){
        width: 100vw;
        padding: 0px;
        min-height: 0px;
    }
`
const Monitor = styled.div`
    width: 120%;
    height: 120%;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("../images/tablet2.png");
    background-position: top;
    background-position: right;
    @media only screen and (max-width:1024px){
        width: 100%;
        background-position: left;
        background-position: bottom;
        height: 250px;
        padding: 0px;
    }
`

const HeaderTitle = styled.div`
    width: 100%;
    color: white;
    font-size: 60px;
    font-weight: bold;
`
const HeaderDescription = styled.div`
    padding-top: 40px;
    width: 100%;
    color: white;
    font-size: 20px;
    font-weight: bold;
    text-align: justify;
`
const CloseContainer = styled.div`
    padding: 10px 20px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    position: absolute;
`
const CloseButton = styled.a`
    //width: 40px;
    //height: 40px;
    padding: 5px 10px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: #ffffff;
    border-radius: 60px;
    cursor: pointer;
    border: 1px solid #ffffff;
    text-decoration: none;
`
const Logo = styled.div`
    font-size: 20px;
    color: #ffffff;
    padding-right: 10px;
`
const HomeButton = styled.div`
    font-size: 20px;
    color: #ffffff;
    padding-right: 10px;
    font-weight: bold;
`
const WorkArea = styled.div`
    @media only screen and (max-width:768px){
        flex-direction: column;
    }
`
const StepContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    @media only screen and (max-width:768px){
        flex-direction: column;
        padding: 20px;
        box-sizing: border-box;
    }
`
const StepContainer2 = styled.div`
    width: 100%;
    display: flex;
    background-color: #f3f3f3;
    align-items: center;
    @media only screen and (max-width:768px){
        flex-direction: column-reverse;
        padding: 20px;
        box-sizing: border-box;
    }
`
const StepBox = styled.div`
    width: 50%;
    margin: 50px;
    box-sizing: border-box;
    @media only screen and (max-width:768px){
        width: 100%; 
    }
    
`
const StepTitle = styled.div`
    font-size: 25px;
    font-weight: bold;
    color: #424242;
`
const StepDetails = styled.div`
    padding-top: 40px;
    width: 100%;
    
`
const StepVideo = styled.div`
    
`

function ScadaPrinciple() {
    return (
        <Container >
            <HeaderContainer>
                <CloseContainer>
                    <CloseButton href="javascript:history.back()" >
                        <Logo className='fas fa-arrow-left' />
                        <HomeButton>
                            HOME
                        </HomeButton>
                    </CloseButton>
                </CloseContainer>
                <HeaderDetails>
                    <HeaderTitle>
                        SCADA
                    </HeaderTitle>
                    <HeaderDescription>
                        Supervisory Control and Data Acquisition (SCADA) is a control system architecture comprising computers, networked data communications and graphical user interfaces for high-level supervision of machines and processes. It also covers sensors and other devices, such as programmable logic controllers, which interface with process plant or machinery.
                    </HeaderDescription>
                </HeaderDetails>
                <HeaderImage>
                    <Monitor />
                </HeaderImage>
            </HeaderContainer>
            <WorkArea>
                <StepContainer>
                    <StepBox>
                        <StepTitle>
                            PLC Configuration / Programming
                        </StepTitle>
                        <StepDetails>
                            <DetailInput topic="We configure our Omron PLC with the CX-Programmer." />
                            <DetailInput topic="We create a program that simulates a machine or work station processing parts." />
                            <DetailInput topic="We open and configure the virtual PLC with CX-Simulator." />
                            <DetailInput topic="Download program to virtual PLC and run the test." />
                        </StepDetails>
                    </StepBox>
                    <StepBox>
                        <StepDetails>
                            <StepVideo>
                                <ReactPlayer
                                    url='videos/SCADA_PLC.mp4'
                                    controls
                                    loop
                                    playing
                                    muted
                                    width='100%'
                                    height='100%'
                                />
                            </StepVideo>
                        </StepDetails>
                    </StepBox>
                </StepContainer>

                <StepContainer2>
                    <StepBox>
                        <StepDetails>
                            <StepVideo>
                                <ReactPlayer
                                    url='videos/SCADA_OPC.mp4'
                                    controls
                                    loop
                                    playing
                                    muted
                                    width='100%'
                                    height='100%'
                                />
                            </StepVideo>
                        </StepDetails>
                    </StepBox>
                    <StepBox>
                        <StepTitle>
                            Omron OPC Configuration
                        </StepTitle>
                        <StepDetails>
                            <DetailInput topic="Create “.cmd” file." />
                            <DetailInput topic="We configure the communication with the PLC, in this example the PLC is the one executed in CX-Simulator." />
                            <DetailInput topic="We create machine stations." />
                            <DetailInput topic='We create TAG "Good Parts" in station 1 of machine 1, granting the PLC configuration and indicating the memory address of the PLC to which we will link this Tag.' />
                        </StepDetails>
                    </StepBox>
                </StepContainer2>

                <StepContainer>
                    <StepBox>
                        <StepTitle>
                            Database
                        </StepTitle>
                        <StepDetails>
                            <DetailInput topic='We create the database "test" using the software "Microsoft SQL Server Management Studio".' />
                            <DetailInput topic='We create the table "machine1" that will store the PLC values, for this we create the id, date, tag and value columns.' />
                        </StepDetails>
                    </StepBox>
                    <StepBox>
                        <StepDetails>
                            <StepVideo>
                                <ReactPlayer
                                    url='videos/SCADA_DATABASE.mp4'
                                    controls
                                    loop
                                    playing
                                    muted
                                    width='100%'
                                    height='100%'
                                />
                            </StepVideo>
                        </StepDetails>
                    </StepBox>
                </StepContainer>

                <StepContainer2>
                    <StepBox>
                        <StepDetails>
                            <StepVideo>
                                <ReactPlayer
                                    url='videos/SCADA_PROGRAM.mp4'
                                    controls
                                    loop
                                    playing
                                    muted
                                    width='100%'
                                    height='100%'
                                />
                            </StepVideo>
                        </StepDetails>
                    </StepBox>
                    <StepBox>
                        <StepTitle>
                            C# Program
                        </StepTitle>
                        <StepDetails>
                            <DetailInput topic="We create a C# Windows Forms Application (.NET Framework) project." />
                            <DetailInput topic="We added Omron references." />
                            <DetailInput topic="We created “Form1” by adding the reference of our “.cmd” file from our OPC Server to our CX SERVER tool from Omron references." />
                            <DetailInput topic='We create DBConnection Class with the information from our database and table.' />
                            <DetailInput topic='We run PLC and C# program, as a result the program should load the PLC information to the database for further use.' />
                        </StepDetails>
                    </StepBox>
                </StepContainer2>

            </WorkArea>
            <DefaulFooter />
        </Container>
    );
}

export default ScadaPrinciple;