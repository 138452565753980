import styled from 'styled-components'
import { useState } from "react";
import React from 'react';
import { ReactDOM } from 'react';

const Container = styled.div`
    //width: 50px;
    //height: 50px;
    border-radius: 30px;
    background-color:#3884F5;
    color: #FFFFFF;
    cursor: pointer;
    display: flex;
    justify-content: initial;
    align-items: center;
    transition-duration: 0.2s;
    overflow: hidden;
    padding: 10px 20px;
    margin-top: 20px;
    &:hover{
        background-color: #2E6FD1;
  }
  @media only screen and (max-width:1024px){
    //width: 40px;
    //height: 40px;
    }

  &:hover span{
        visibility: visible;
        opacity: 1;
  }

`

function PWAInstallButton() {

   
    


    return (
        <Container>
           Install app
        </Container>
    );
}

export default PWAInstallButton;