import React, { Fragment } from 'react';
import styled from 'styled-components';
import ReactDOM from 'react-dom/client';



const Container = styled.div`
    width: 500px;
    height: 550px;
    margin: 10px;
    @media only screen and (max-width:1024px){
        width: 40%;
        height: 420px;
    }
    @media only screen and (max-width:768px){
        width: 100%;
    }
`

const BaseContainer = styled.div`
    width: 500px;
    height: 400px;
    margin-top: 150px;
    position: absolute;
    -webkit-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.75);
    @media only screen and (max-width:1024px){
        width: calc(40% - 40px);
        height: 280px;
    }
    @media only screen and (max-width:768px){
        width: calc(100% - 40px);
    }
`
const CarouselContainer = styled.div`
    width: 300px;
    height: 430px;
    position: relative;
    margin-left: 200px;
    overflow: hidden;
    @media only screen and (max-width:1024px){
        width: 250px;
        margin-left: calc(100% - 250px);
    }
    @media only screen and (max-width:768px){
        width: 300px;
        margin-left: calc(100% - 300px);
        height: 350px;
    }
`
const ImageBase = styled.div`
    width: 500px;
    height: 280px;
    display: flex; 
    justify-content: center;
    flex-flow: wrap;
    overflow: hidden;
    @media only screen and (max-width:1024px){
        width: 100%;
        height: 200px;
    }
`
const Image = styled.img`
    width:  ${props => props.stretch_image ? "auto" : "100%"};
    height:  ${props => props.stretch_image ? "100%" : "auto"};
`
const CarouselImage = styled.img`
    width: 100%;
    border: none;
    -webkit-box-shadow: 0px 0px 1px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 1px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 1px 0px rgba(0,0,0,0.75);
    animation: slide 15s infinite normal ease-in-out;
    @keyframes slide {
  0%   {margin-top:200%; opacity:1;}
  25%  {margin-top:30%; opacity:1;}
  50%  {margin-top:30%; opacity:1;}
  75%  {margin-top:30%; opacity:1;}
  100% {margin-top:0%; opacity:0;}
}
@media only screen and (max-width:1024px){
        width: 100%;
    }
@media only screen and (max-width:768px){
        width: 100%;
    }
    
`

const DetailsContainer = styled.div`
    width: 500px;
    height: 120px;
    display: flex;
    border-top: 1px solid #3884F5;
    @media only screen and (max-width:1024px){
        width: 100%;
        height: 80px;
    }
`
const DetailsTitle = styled.div`
    width: 500px;
    height: 120px;
    font-size: 28px;
    font-weight: bold;
    padding: 0px 20px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    color: #3884F5;
    @media only screen and (max-width:1024px){
        width: 100%;
        height: 80px;
        font-size: 18px;
    }
`
const DetailsMoreButton = styled.div`
    width: 120px;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media only screen and (max-width:1024px){
        height: 80px;
    }
`
const MoreButton = styled.a`
    width: 40px;
    height: 40px;
    border: 1px solid #3884F5;;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
    &:hover{
    background-color: #f7f7f7;
  }
`
const Icon = styled.i`
    font-size: 25px;
    color: #3884F5;
`


function PortfolioCard(props) {
    return (
        <Container >
            <BaseContainer>
                <ImageBase>
                    <Image src={props.image} stretch_image={props.stretch_image} />
                </ImageBase>

                <DetailsContainer>
                    <DetailsTitle>
                        {props.title}
                    </DetailsTitle>
                    <DetailsMoreButton>
                        <MoreButton href={props.path}>
                            <Icon className="fas fa-plus" />
                        </MoreButton>    
                    </DetailsMoreButton>
                </DetailsContainer>

            </BaseContainer>
            <CarouselContainer>
                <CarouselImage src={props.carousel_image} />
            </CarouselContainer>
        </Container>
    );
}

export default PortfolioCard;