import React from 'react';
import styled from 'styled-components';
import ReactPlayer from 'react-player';
import DetailInput from '../others/DetailInput';
import DefaulFooter from '../../views/DefaulFooter';



const Container = styled.div`
    width: 100%;
    min-height: 100vh;
    box-sizing: border-box;
`
const HeaderContainer = styled.div`
    width: 100%;
    color: white;
    background-color: #f3f3f3;
    background-image: url("../images/dexter/dexterbase.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    display: flex;
    @media only screen and (max-width:1024px){
        flex-direction:column;
    }
`
const HeaderDetails = styled.div`
    width: 50%;
    padding: 120px;
    box-sizing: border-box;
    background-color: #0000009c;
    @media only screen and (max-width:1024px){
        width: 100%;
        padding: 50px;
    }
`
const HeaderImage = styled.div`
    width: 50%;
    padding: 120px 0px 0px 0px;
    box-sizing: border-box;
    background-color: #0000009c;
    overflow: hidden;
    min-height: 600px;
    @media only screen and (max-width:1024px){
        width: 100vw;
        padding: 0px;
        min-height: 0px;
    }
`
const Monitor = styled.div`
    width: 120%;
    height: 120%;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("../images/dexter/ticketscreen.png");
    background-position: top;
    background-position: right;
    @media only screen and (max-width:1024px){
        width: 100%;
        background-position: left;
        background-position: bottom;
        height: 250px;
        padding: 0px;
    }
`

const HeaderTitle = styled.div`
    width: 100%;
    color: white;
    font-size: 60px;
    font-weight: bold;
`
const HeaderDescription = styled.div`
    padding-top: 40px;
    width: 100%;
    color: white;
    font-size: 20px;
    font-weight: bold;
    text-align: justify;
`
const CloseContainer = styled.div`
    padding: 10px 20px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    position: absolute;
`
const CloseButton = styled.a`
    //width: 40px;
    //height: 40px;
    padding: 5px 10px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: #ffffff;
    border-radius: 60px;
    cursor: pointer;
    border: 1px solid #ffffff;
    text-decoration: none;
`
const Logo = styled.div`
    font-size: 20px;
    color: #ffffff;
    padding-right: 10px;
`
const HomeButton = styled.div`
    font-size: 20px;
    color: #ffffff;
    padding-right: 10px;
    font-weight: bold;
`
const WorkArea = styled.div`
    @media only screen and (max-width:768px){
        flex-direction: column;
    }
`
const StepContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    @media only screen and (max-width:768px){
        flex-direction: column;
        padding: 20px;
        box-sizing: border-box;
    }
`
const StepContainer2 = styled.div`
    width: 100%;
    display: flex;
    background-color: white;
    align-items: center;
    @media only screen and (max-width:768px){
        flex-direction: column-reverse;
        padding: 20px;
        box-sizing: border-box;
    }
`
const StepBox = styled.div`
    width: 50%;
    margin: 50px;
    box-sizing: border-box;
    @media only screen and (max-width:768px){
        width: 100%; 
    }
`
const StepTitle = styled.div`
    font-size: 25px;
    font-weight: bold;
    color: #424242;
`
const StepDetails = styled.div`
    padding-top: 40px;
    width: 100%;
`
const StepVideo = styled.div`
    
`
const Emp = styled.span`
    color: #3884F5;
    font-weight: 550;
`
const Image = styled.img`
    width: 100%;
    border: 1px gainsboro solid;
`
const SectionSeparator = styled.div`
    width:100%;
    height: 3px;
    border-top: 1px dashed gray;
`

function Dexter() {
    return (
        <Container >
            <HeaderContainer>
                <CloseContainer>
                    <CloseButton href="javascript:history.back()" >
                        <Logo className='fas fa-arrow-left' />
                        <HomeButton>
                            HOME
                        </HomeButton>
                    </CloseButton>
                </CloseContainer>
                <HeaderDetails>
                    <HeaderTitle>
                        Help Desk System
                    </HeaderTitle>
                    <HeaderDescription>
                    The Help Desk system made it possible to track activities thanks to the input from clients and the same controls team, since the system allows obtaining a ticket per support or per project and the activities carried out are recorded within the same ticket, generating evidence, this It allowed us to generate automated reports that provide us with useful information for the development of strategies, for example, which areas consume the greatest resource, ratios, speeding up response time, generation of highlights, record of impact generated in OEE metrics, etc.                    </HeaderDescription>
                </HeaderDetails>
                <HeaderImage>
                    <Monitor />
                </HeaderImage>
            </HeaderContainer>
            <WorkArea>
                <StepContainer>
                    <StepBox>
                        <StepTitle>
                            Work Order Summary
                        </StepTitle>
                        <StepDetails>
                            <Emp>Work Order</Emp> Summary <Emp>Viewer</Emp> and segregation by  <Emp>filtering</Emp> 
                        </StepDetails>
                    </StepBox>
                    <StepBox>
                        <StepDetails>
                            <Image src='images/dexter/dexterbase.png' />
                        </StepDetails>
                    </StepBox>
                </StepContainer>
                <SectionSeparator />
                <StepContainer2>
                    <StepBox>
                        <StepDetails>
                            <Image src='images/dexter/dexter_request.png' />
                        </StepDetails>
                    </StepBox>
                    <StepBox>
                        <StepTitle>
                            Request Screen
                        </StepTitle>
                        <StepDetails>
                            Dynamic user <Emp>request </Emp> with  < Emp>automatic priority </Emp> assignment
                        </StepDetails>
                    </StepBox>
                </StepContainer2>
                <SectionSeparator />
                <StepContainer>
                    <StepBox>
                        <StepTitle>
                            Ticket Details
                        </StepTitle>
                        <StepDetails>
                            Details Viewer for Team Members and Clients
                        </StepDetails>
                    </StepBox>
                    <StepBox>
                        <StepDetails>
                            <Image src='images/dexter/ticketScreen.png' />
                        </StepDetails>
                    </StepBox>
                </StepContainer>
                <SectionSeparator />
                <StepContainer2>
                    <StepBox>
                        <StepDetails>
                            <Image src='images/dexter/dexter_follow_up.png' />
                        </StepDetails>
                    </StepBox>
                    <StepBox>
                        <StepTitle>
                            Follow Up Screen
                        </StepTitle>
                        <StepDetails>
                            Additional Information about Reparation Time and Status Change Control
                        </StepDetails>
                    </StepBox>
                </StepContainer2>
                <SectionSeparator />
                <StepContainer>
                    <StepBox>
                        <StepTitle>
                            Supervisor Screen
                        </StepTitle>
                        <StepDetails>
                            Activity manager (visible only to the team supervisor)
                        </StepDetails>
                    </StepBox>
                    <StepBox>
                        <StepDetails>
                            <Image src='images/dexter/dexter_supervisor.png' />
                        </StepDetails>
                    </StepBox>
                </StepContainer>
                <SectionSeparator />
                <StepContainer2>
                    <StepBox>
                        <StepDetails>
                            <Image src='images/dexter/dexter_metrics.png' />
                        </StepDetails>
                    </StepBox>
                    <StepBox>
                        <StepTitle>
                            Work Order Report
                        </StepTitle>
                        <StepDetails>
                            
                        </StepDetails>
                    </StepBox>
                </StepContainer2>
                <SectionSeparator />
                <StepContainer>
                    <StepBox>
                        <StepTitle>
                            Email
                        </StepTitle>
                        <StepDetails>
                            Notification Email about Status Changes, Comments in Chat and Ticket Assignment
                        </StepDetails>
                    </StepBox>
                    <StepBox>
                        <StepDetails>
                            <Image src='images/dexter/dexter_email.png' />
                        </StepDetails>
                    </StepBox>
                </StepContainer>
                <SectionSeparator />
                <StepContainer2>
                    <StepBox>
                        <StepDetails>
                            <Image src='images/dexter/dexter_teams.png' />
                        </StepDetails>
                    </StepBox>
                    <StepBox>
                        <StepTitle>
                            Teams Message
                        </StepTitle>
                        <StepDetails>
                            Notification Message about New Ticket
                        </StepDetails>
                    </StepBox>
                </StepContainer2>
                <SectionSeparator />
                <StepContainer>
                    <StepBox>
                        <StepTitle>
                            Database
                        </StepTitle>
                        <StepDetails>
                            Multiple Microsoft List Integration
                        </StepDetails>
                    </StepBox>
                    <StepBox>
                        <StepDetails>
                            <Image src='images/dexter/dexter_database.png' />
                        </StepDetails>
                    </StepBox>
                </StepContainer>

            </WorkArea>
            <DefaulFooter />
        </Container>
    );
}

export default Dexter;