import React from 'react';
import styled from 'styled-components';


const Container = styled.div`
    width: 100%;
    color: #424242;
    font-weight: bold;
    font-size: 12px;
    padding: 20px;
    box-sizing: border-box;
`
const Header = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
`
const BarBase = styled.div`
    width: 100%;
    height: 8px;
    background-color: #EAEAEA;

`
const Bar = styled.div`
    width: ${props => props.percent ? ""+props.percent+"%" : " 0%"};
    height: 8px;
    background-color: #3884F5;

`


function Percent(props) {
    return (
        <Container >
            <Header>
                <div>
                    {props.text}
                </div>
                <div>
                    {props.percent + "%"}
                </div>
            </Header>
            <BarBase>
                <Bar  percent={props.percent} />
            </BarBase>
        </Container>
    );
}

export default Percent;