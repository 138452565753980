import React, { Component } from 'react';
import styled from 'styled-components';

/*
Inputs
[text]
*/

let defaultColor = "#3884F5";
let activeColor = "#3884f5df";
let hoverDefaultColor = "#2b7df8";
let fontSizeDefault = "14px";
let paddingDefault = "10px 20px";

const Button = styled.button`
  display: "inline-block";
  background-color: ${defaultColor};
  color: #ffff;
  cursor: pointer;
  position: relative;
  border: none;
  border-radius: 100px;

  &:hover{
    background-color: ${hoverDefaultColor};
  }

  &:active{
    background-color: ${activeColor};
  }
`

class DefaultButton extends Component {
  //States
  constructor(props) {
    super(props);

    this.state = {
      active: this.props.active
    };
  };

  Properties() {
    var newFontSizer = fontSizeDefault;
    var newPadding = paddingDefault;
    if (this.props.fontSize) {
      newFontSizer = this.props.fontSize;
    }

    if (this.props.padding) {
      newPadding = this.props.padding;
    }
    return {
      fontSize: newFontSizer,
      padding: newPadding,
    }
  }

  //Events
  Execution = e => {
    this.setState(prevState => ({
      active: !prevState.active,
    }));
  }

  render() {
    return (
      <Button
        onMouseDown={this.Execution}
        onMouseUp={this.Execution}
        style={this.Properties()} >
        {this.props.text}
      </Button>
    )
  }
}

export default DefaultButton;