import React, { useRef } from 'react';
import styled from 'styled-components';
import DefaultHeader from '../components/headers/DefaultHeader';
import Introduction from '../components/others/Introduction';
import ContactData from '../components/others/ContactData';
import DefaultButton from '../components/buttons/DefaultButton';
import emailjs from '@emailjs/browser';


const Container = styled.div`
    width: 100%;
    padding: 0px 25px 25px 110px;
    box-sizing: border-box;
    @media only screen and (max-width:1024px){
        padding: 0px 50px 50px 50px;
    }
    @media only screen and (max-width:768px){
        padding: 0px 10px 50px 10px;
    }
    
`
const WorkArea = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    @media only screen and (max-width:768px){
        flex-direction: column;
    }
`

const DetailsContainer = styled.div`
    width: 30%;
    @media only screen and (max-width:768px){
        width: 100%;
    }
`
const MessageContainer = styled.form`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px;
    box-sizing: border-box;
    width: 70%;
    @media only screen and (max-width:768px){
        width: 100%;
    }
`

const Input = styled.input`
    width: calc(50% - 10px);
    outline: none;
    border: solid 1px #969696;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 5px;
    margin-bottom: 20px;
    @media only screen and (max-width:768px){
        width: 100%;
    }
`
const Subject = styled.input`
    width: 100%;
    outline: none;
    border: solid 1px #969696;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 5px;
    margin-bottom: 20px;
`
const Message = styled.textarea`
    width: 100%;
    outline: none;
    border: solid 1px #969696;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 5px;
    margin-bottom: 20px;
   // height: 50px;
`
const ButtonContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: right;
`
const ButtonBox = styled.div`
    width: 70%;
    text-align: center;
    @media only screen and (max-width:768px){
        width: 100%;
    }
`

function Contact() {
    const form = useRef();

    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs.sendForm('service_3wwgriv', 'template_m05skyq', form.current, '0E_NcDGJkrz1HKOw1')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
    };

    return (
        <Container >
            <div>
                <DefaultHeader title="Contact" />
                <Introduction
                    text=""
                />

                <WorkArea>
                    <DetailsContainer>
                        <ContactData
                            title="Location"
                            details="Reynosa, Tamaulipas, México"
                            icon="fas fa-map-marker-alt"
                        />
                        <ContactData
                            title="Email"
                            details="juancamposservin@outlook.com"
                            icon="far fa-envelope"
                        />
                        <ContactData
                            title="Call"
                            details="+52 899 1387668"
                            icon="fas fa-phone"
                        />

                    </DetailsContainer>
                    <MessageContainer ref={form}>
                        <Input placeholder='Name' type="text" name="user_name" />
                        <Input placeholder='Email' type="email" name="user_email" />
                        <Subject placeholder='Subject' type="text" name="subject" />
                        <Message placeholder='Message' rows="5" cols="50" name="message" />

                    </MessageContainer>
                    <ButtonContainer>
                        <ButtonBox>
                            <span onClick={sendEmail}>
                                <DefaultButton text="Send Message"  />
                            </span>
                            
                        </ButtonBox>
                    </ButtonContainer>

                </WorkArea>

            </div>
        </Container>
    );
}

export default Contact;