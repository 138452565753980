import React from 'react';
import { useState } from "react";
import styled from 'styled-components';
import DefaultHeader from '../components/headers/DefaultHeader';
import Introduction from '../components/others/Introduction';
import DetailInput from '../components/others/DetailInput';
import Percent from '../components/others/Percent';
import Skill from '../components/others/Skill';

const Container = styled.div`
    width: 100%;
    min-height: 100vh;
    padding: 0px 25px 25px 110px;
    box-sizing: border-box;
    @media only screen and (max-width:1024px){
        padding: 0px 50px 50px 50px;
    }
    @media only screen and (max-width:768px){
        padding: 0px 10px 50px 10px;
    }
`
const PersonalInformation = styled.div`
    width: 100%;
    padding: 60px 0px;
    box-sizing: border-box;
    display: flex;
    @media only screen and (max-width:1024px){
        flex-direction: column;
        align-items: center;
    }

`
const Photo = styled.div`
    width: 350px;
    height: 350px;
    background-image: url('images/juan.jpg');
    background-size: cover;
`
const DetailsContainer = styled.div`
    width: calc(100% - 360px);
    padding: 0 0 0 20px;
    @media only screen and (max-width:1024px){
        width: 100%;
        padding: 0px;
    }
`

const DetailsTitle = styled.div`
    width: 100%;
    padding: 0 0 20px 20px;
    font-size: 25px;
    font-weight: bold;
    color:#5f6d83;
    @media only screen and (max-width:1024px){
        padding: 60px 0 20px 0;
        text-align: center;
    }
`
const DetailsContainerSkills = styled.div`
    width: 100%;
`
const DetailsBox = styled.div`
    display: flex;
    @media only screen and (max-width:1024px){ 
        flex-direction: column;
    }
`
const Details = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    @media only screen and (max-width:1024px){
        width: 100%;   
    }
`
const SkillSubSectionContainer = styled.div`
    width: 100%;
    padding:10px;
    box-sizing: border-box;
`
const SkillSubSection = styled.div`
    width: 100%;
    margin-bottom: 20px;
    -webkit-box-shadow: 0px 0px 1px 0px rgba(0,0,0,0.75);
-moz-box-shadow: 0px 0px 1px 0px rgba(0,0,0,0.75);
box-shadow: 0px 0px 1px 0px rgba(0,0,0,0.75);
`
const SkillSubSectionText = styled.div`
    color: #3884F5;
    font-weight: bold;
    padding: 20px;
    box-sizing: border-box;
`



function About() {
    return (
        <Container >
            <div>
                <DefaultHeader title="ABOUT" />
                <Introduction
                    text="Mechatronics Engineer specialized in industrial automation, with experience in control systems and mechanical design, as well as skills in developing desktop, web and database applications."
                />
                <PersonalInformation>
                    <Photo />
                    <DetailsContainer>
                        <DetailsTitle>
                            Mechatronics Engineer specialized in Industrial Automation
                        </DetailsTitle>
                        <DetailsBox>
                            <Details>
                                <DetailInput topic="Birthday: " text="10 December 1993" />
                                <DetailInput topic="linkedIn: " text={<a className='correo' href="https://www.linkedin.com/in/juan-alfonso-campos-servin" target="_blank">www.linkedin.com/in/juan-alfonso-campos-servin</a>} />
                                <DetailInput topic="Phone: " text={<a className='correo' href="tel:+528991387668">+52 899 138 7668</a>} />
                                <DetailInput topic="City: " text="Reynosa, Tamaulipas" />
                            </Details>
                            <Details>
                                <DetailInput topic="Age: " text="29" />
                                <DetailInput topic="Degree: " text="Engineering in Mechatronics" />
                                <DetailInput topic="Email: " text={<a className='correo' href="mailto:juancamposservin@outlook.com?Subject=Portfolio%20web">juancamposservin@outlook.com</a>} />
                            </Details>
                        </DetailsBox>
                    </DetailsContainer>
                </PersonalInformation>
            </div>


        </Container>
    );
}

export default About;