import React from 'react';
import { useState } from 'react';
import { useRef } from 'react';
import styled from 'styled-components';
import Home from './views/Home';
import About from './views/About';
import Education from './views/Education';
import Skills from './views/Skills';
import Resume from './views/Resume';
import Portfolio from './views/Portfolio';
import Contact from './views/Contact';
import DefaulFooter from './views/DefaulFooter';
import MenuButton from './components/buttons/MenuButton';
import UpButton from './components/buttons/UpButton';
import BurgerButton from './components/buttons/BurgerButton';


const Menu = styled.div`
    position: fixed;
    width: 100px;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    z-index: 100;
    padding: 10px;
    box-sizing: border-box;
    @media only screen and (max-width:1024px){
        transition-duration: 0.5s;
        width: 300px;
        margin-left: -305px;
        background-color: white;
        -webkit-box-shadow: 0px 0px 1px 0px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px 0px 1px 0px rgba(0,0,0,0.75);
        box-shadow: 0px 0px 1px 0px rgba(0,0,0,0.75);
        overflow-y: scroll;
        
    }
    @media only screen and (max-height:500px)  and (orientation: landscape) {
      justify-content: start;
    }
`
const Span = styled.span`
    padding: 5px;
    box-sizing: border-box;

`
const Linked = styled.div`

`
const TopButton = styled.div`
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 100;
  margin: 30px;
  @media only screen and (max-width:1024px){
    margin: 10px
    }
`
const BurgerButtonContainer = styled.div`
  right: 0;
  top: 0;
  position: fixed;
  z-index: 100;
  margin: 10px;
  display: none;
  @media only screen and (max-width:1024px){
    display: block;
    }
`

function useForceUpdate() {
  const [value, setValue] = useState(0); // integer state
  return () => setValue(value => value + 1); // update state to force render
  // A function that increment 👆🏻 the previous state like here 
  // is better than directly setting `setValue(value + 1)`
}

function App() {
  const [menu, setMenu] = useState([true, false, false, false, false, false, false]);
  const [upButton, setUpButton] = useState(false);
  const [burgerButtonState, setBurgerButtonState] = useState(false);
  const [burgerButtonIcon, setBurgerButtonIcon] = useState("fas fa-bars");
  const forceUpdate = useForceUpdate();

  const home = useRef(null);
  const about = useRef(null);
  const education = useRef(null);
  const skills = useRef(null);
  const resume = useRef(null);
  const portfolio = useRef(null);
  const contact = useRef(null);

  const scrollToSection = (elementRef, MenuButton) => {
    var length = menu.length;
    var menuId = document.getElementById("menu");

    if(burgerButtonState){
      menuId.style.marginLeft="-305px";
      setBurgerButtonState(false);
      setBurgerButtonIcon("fas fa-bars")
    }

    window.scrollTo({
      top: elementRef.current.offsetTop,
      behavior: 'smooth',
    });
  }

  window.onscroll = function () {
    scrollStart();
  };

  document.ontouchmove = function () {
    scrollStart();
  };

  function scrollStart() {
    var viewport = window.innerHeight;
    var home = document.getElementById("home").getBoundingClientRect();
    var about = document.getElementById("about").getBoundingClientRect();
    var education = document.getElementById("education").getBoundingClientRect();
    var skills = document.getElementById("skills").getBoundingClientRect();
    var resume = document.getElementById("resume").getBoundingClientRect();
    var portfolio = document.getElementById("portfolio").getBoundingClientRect();
    var contact = document.getElementById("contact").getBoundingClientRect();
    var porcent = viewport * 0.5;
    var y = window.scrollY;

    if (y > 300) {
      setUpButton(true);
    } else {
      setUpButton(false);
    }

    if (home.top < porcent) {
      slideScreen(0);
    }
    if (about.top < porcent) {
      slideScreen(1);
    }
    if (education.top < porcent) {
      slideScreen(2);
    }
    if (skills.top < porcent) {
      slideScreen(3);
    }
    if (resume.top < porcent) {
      slideScreen(4);
    }
    if (portfolio.top < porcent) {
      slideScreen(5);
    }
    if (contact.top < porcent) {
      slideScreen(6);
    }
  }

  function slideScreen(MenuButton) {
    for (var i = 0; i < menu.length; i++) {
      if (MenuButton == i) {
        menu[i] = true;
      } else {
        menu[i] = false;
      }

    }
    forceUpdate();
  }

    const burgerButtonClick = () => {
      var menu = document.getElementById("menu");
      document.getElementById("menuInfoContainer").style.display="none";

      setBurgerButtonState(!burgerButtonState);

      if(!burgerButtonState){
        setBurgerButtonIcon("fas fa-times");
        menu.style.marginLeft="0px";
      }else{
        setBurgerButtonIcon("fas fa-bars");
        menu.style.marginLeft="-305px";
      }
    }

  return (
    <div className='screen' >
      <BurgerButtonContainer onClick={burgerButtonClick}>
        <BurgerButton icon={burgerButtonIcon} active={burgerButtonState} />
      </BurgerButtonContainer>
      <Menu id='menu'>
        <Span><Linked onClick={() => scrollToSection(home, 0)}><MenuButton title="Home" icon="fas fa-home" active={menu[0]} /></Linked></Span>
        <Span><Linked onClick={() => scrollToSection(about, 1)}><MenuButton title="About" icon="far fa-user" active={menu[1]} /></Linked></Span>
        <Span><Linked onClick={() => scrollToSection(education, 2)}><MenuButton title="Education" icon="fa fa-graduation-cap" active={menu[2]} /></Linked></Span>
        <Span><Linked onClick={() => scrollToSection(skills, 3)}><MenuButton title="Skills" icon="fas fa-dumbbell" active={menu[3]} /></Linked></Span>
        <Span><Linked onClick={() => scrollToSection(resume, 4)}><MenuButton title="Experience" icon="far fa-file" active={menu[4]} /></Linked></Span>
        <Span><Linked onClick={() => scrollToSection(portfolio, 5)}><MenuButton title="Portfolio" icon="fas fa-briefcase" active={menu[5]} /></Linked></Span>
        <Span><Linked onClick={() => scrollToSection(contact, 6)}><MenuButton title="Contact" icon="far fa-envelope" active={menu[6]} /></Linked></Span>
      </Menu>

      <div id='home' ref={home} >
        <Home />
      </div>
      <div id='about' ref={about} >
        <About />
      </div>
      <div id='education' ref={education} >
        <Education />
      </div>
      <div id='skills' ref={skills} >
        <Skills />
      </div>
      <div id='resume' ref={resume} >
        <Resume />
      </div>
      <div id='portfolio' ref={portfolio} >
        <Portfolio />
      </div>
      <div id='contact' ref={contact} >
        <Contact />
      </div>
      <div >
        <DefaulFooter />
      </div>

      <TopButton onClick={() => scrollToSection(home, 0)}>
        <UpButton icon="fas fa-chevron-up" active={upButton} />
      </TopButton>

    </div>
  );
}

export default App;
