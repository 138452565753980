import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    width: 250px;
`
const TriangleTop = styled.div`
    display: ${props => props.positionTop === 0 ? "block" : "none"};
    width: 40px;
    height: 40px;
    transform: rotate(45deg);
    background-color:  white;
    margin: auto;
    position: relative;
    top: 20px;
    margin-top: -20px;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
-moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
`
const TriangleBottom = styled.div`
 display: ${props => props.positionBottom == 2 ? "block" : "none"};
    width: 40px;
    height: 40px;
    transform: rotate(45deg);
    background-color:  white;
    margin: auto;
    position: relative;
    bottom: 20px;
    margin-bottom: -20px;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
-moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
`
const TriangleLeft = styled.div`
 display: ${props => props.positionLeft == 3 ? "block" : "none"};
    width: 40px;
    height: 40px;
    transform: rotate(45deg);
    background-color:  white;
    margin-left: -20px;
    position: relative;
    top: 50%;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
-moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
`
const TriangleRight = styled.div`
 display: ${props => props.positionRight == 1 ? "block" : "none"};
    width: 40px;
    height: 40px;
    transform: rotate(45deg);
    background-color:  white;
    margin-left: 230px;
    position: relative;
    bottom: -50%;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
-moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
`

const Box = styled.div`
    width: 250px;
    background-color: white;
    border-radius: 5px;
    z-index:1;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
-moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
`
const Career = styled.div`
    background-color: white;
    position: relative;
    border-radius: 5px 5px 0 0;
    padding: 10px 10px 0 10px;
    box-sizing: border-box;
    z-index:1;
    font-weight: bold;
    font-size: 15px;
    color:#5f6d83;
    text-align: center;
    @media only screen and (max-height:400px)  and (orientation: landscape) {
        font-size: 10px;
    }
`
const School = styled.div`
    background-color: white;
    position: relative;
    padding: 5px 10px;
    box-sizing: border-box;
    color: #424242;
    font-weight: bold;
    font-size: 10px;
    z-index:1;
`
const Date = styled.div`
    background-color: white;
    padding: 0 10px 10px 10px;
    box-sizing: border-box;
    border-radius: 0 0 5px 5px;
    position: relative;
    font-size: 10px;
    font-weight: bold;
    z-index:1;
    text-align: end;
    color: #3884F5;
`


function EducationCard(props) {
    return (
        <Container >
            <TriangleTop positionTop={props.showPosition} />
            
            <TriangleLeft positionLeft={props.showPosition} />
            <TriangleRight positionRight={props.showPosition} />
            <Box>
                <Career>
                    {props.career}
                </Career>
                <School>
                    {props.school}
                </School>
                <Date>
                    {props.date}
                </Date>
            </Box>
            <TriangleBottom	positionBottom={props.showPosition} />


        </Container>
    );
}

export default EducationCard;