import React from 'react';
import styled from 'styled-components';
import EducationCard from '../cards/EducationCard';

const Container = styled.div`
    width: 100%;
    max-width: 1000px;
    @media only screen and (max-width:768px){
        height: 100%;
        max-height: 1000px;
    }
`
const LineContainer = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 22.5px 0 15px 0;
    box-sizing: border-box;
`
const Line = styled.div`
    width: calc(100% - 250px);
    height: 2px;
    background-color: #3884F5;
  
`
const PointContainer = styled.div`
    width: calc(100% - 235px);
    display: flex;
    justify-content: space-between;
    margin-top: -7.5px;
`

const Point = styled.div`
    width: 15px;
    height: 15px;
    border-radius: 10px;
    background-color: #3884F5; 
`
const CardsContainerTop = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`
const CardsContainerBottom = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between; 
    box-sizing: border-box;
`

function HorizontalTimeline() {
    return (
        <Container >
            <CardsContainerTop>
                <EducationCard
                    career="Higher University Technician in Mechatronics Automation area"
                    school="Universidad Tecnológica de Tamaulipas Norte"
                    date="2017-2019"
                    showPosition={2}
                />
            </CardsContainerTop>
            <LineContainer>
                <Line />
                <PointContainer>
                    <Point />
                    <Point />
                    <Point />
                </PointContainer>

            </LineContainer>


            <CardsContainerBottom>
                <EducationCard
                    career="Technical Professional Bachelor in Industrial Electromechanics"
                    school="Col. Nacional de Educación Profesional
                    Técnica.
                    "
                    date="2009-2012"
                    showPosition={0}
                />

                <EducationCard
                    career="Bachelor's Degree in Mechatronic Engineering"
                    school="Universidad Tecnológica de Tamaulipas Norte."
                    date="2019-2021"
                    showPosition={0}
                />
            </CardsContainerBottom>


        </Container>
    );
}

export default HorizontalTimeline;