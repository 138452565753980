import React from 'react';
import { useState } from "react";
import styled from 'styled-components';
import PWAInstallButton from '../components/buttons/PWAInstallButton';
import Typewriter from 'typewriter-effect';

const Container = styled.div`
    width: 100%;
    min-height: 100vh;
    background-image: url("images/computer.jpg");
    background-size: cover;
    background-position-y: bottom;
    background-position-x: center;
`
const Glass = styled.div`
    width: 100%;
    height: 100vh;
    background-color: white;
    opacity: .85;
    position: absolute;
`
const Hello = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    flex-direction: column;
`
const Name = styled.div`
    font-size: 100px;
    font-weight: bold;
    @media only screen and (max-width:1024px){
        font-size: 50px; 
    }
`
const Text = styled.span`
    font-size: 40px; 
    @media only screen and (max-width:1024px){
        font-size: 25px; 
    }
`
const ScrollContainer = styled.span`
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    color: #3884F5;
`
const Icon = styled.div`
    font-size: 20px;
    padding: 10px;
`
const Icon2 = styled.div`
    padding: 10px;
`
const ScrollText = styled.div`
    font-weight: bold;
    padding: 10px 0px 20px 0px;
`
const MenuInfoContainer = styled.div`
    position: fixed;
    width: 100%;
    display: none;  
    justify-content: right;
    color: #3884F5;
    box-sizing: border-box;
    align-items: center;
    z-index: 2;
`
const HandCircle = styled.span`
    width: 60px;
    height: 60px;
    background-color: #3884F5;
    border-radius: 30px;
    margin-left: 10px;
`

function Home() {
    return (
        <Container >
            <Glass />
            
            <Hello className='notranslate '>
                <Name>
                    Juan Campos
                </Name>
                <Text id='profession'>
                    <Typewriter
                        options={{
                            autoStart: true,
                            loop: true,
                        }}
                        onInit={(typewriter) => {
                            typewriter
                                .typeString("Hello World")
                                .pauseFor(1000)
                                .deleteAll()
                                .typeString("<span>I'm a</span> <span style='color: #2E6FD1;'>Mechatronics Engineer</span>")
                                .pauseFor(2000)
                                .start();
                        }}
                    />
                </Text>
  
                
            </Hello>
            <ScrollContainer>
                <Icon className='fas fa-mouse' />
                <Icon2 className='fas fa-chevron-down' />
                <ScrollText>
                    SCROLL DOWN
                </ScrollText>
            </ScrollContainer>
            <MenuInfoContainer id='menuInfoContainer'>
                <HandCircle />
            </MenuInfoContainer>

        </Container>
    );
}

export default Home;