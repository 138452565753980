import React, { Fragment } from 'react';
import styled from 'styled-components';
import DefaultHeader from '../components/headers/DefaultHeader';
import Introduction from '../components/others/Introduction';
import PortfolioCard from '../components/cards/PortfolioCard';
import Iframe from 'react-iframe'

const Container = styled.div`
    width: 100%;
    /*min-height: 100vh;*/
    padding: 0px 25px 25px 110px;
    box-sizing: border-box;
    @media only screen and (max-width:1024px){
        padding: 0px 50px 50px 50px;
    }
    @media only screen and (max-width:768px){
        padding: 0px 10px 50px 10px;
    }
`
const WorkArea = styled.div`
    width: calc(100% - 0px);
    /*height: calc(100vh - 164px);*/
    display: flex;
    justify-content: center;
    align-items: center; 
    flex-wrap: wrap;
    @media only screen and (max-width:1024px){
        width: 100%;
        height: calc(100vh - 189px);
    }
    @media only screen and (max-width:768px){
        width: 100%;
        height: auto;
    }
`
const PortfolioDetailsBackground = styled.div`
    width: 100vw;
    height: 100vh;
    background-color: #000000e6;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10000;
    overflow: hidden;
    padding: 40px 80px;
    box-sizing: border-box;
`
const PortfolioDetailsContainer = styled.div`
    width: 100%;
    height: 100%;
    background-color: white;
`

const WorkAreaLimit = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
`

const CardContainer = styled.a`
    width: 300px;
    height: 300px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    font-weight: bold;
    color:#5f6d83;
    text-decoration: none;
    overflow: hidden;
    margin: 20px;
    &:hover{
    background-color: #f7f7f7;
  }
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
-moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
`


const CardContainerLong = styled.a`
    width: 600px;
    height: 300px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    font-weight: bold;
    color:#5f6d83;
    text-decoration: none;
    overflow: hidden;
    margin: 20px;
    &:hover{
    background-color: #f7f7f7;
  }
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
-moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);sillontiangu
box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
`

const CardContainerScadaBackground = styled.div`
    width: 100%;
    height: 100%;
    background-image: url("../images/world.jpg");
    background-size: cover;
    background-position: center;  
`
const CardContainerAcademicProjectsBackground = styled.div`
    width: 100%;
    height: 100%;
    background-image: url("../images/academic.jpg");
    background-size: cover;
    background-position: center;  
`
const CardContainerScada = styled.div`
    width: 100%;
    height: 100%;
    background-color: #0000009c;
    color: white;
    padding: 15px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
`
const CardContainerMechanicalDesignBackground = styled.div`
    width: 100%;
    height: 100%;
    background-image: url("../images/Mechanical.jpg");
    background-size: cover;
    background-position: center;  
`
const CardTitle = styled.div`
    
`
const CardDescription = styled.div`
    font-size: 16px;
    color: #95f3ff;
`
const IconsContainer = styled.div`
    display: flex;
    justify-content: space-between;
`
const Icon = styled.div`
    font-size: 18px;
    color: #95f3ff;
`
const SeeMore = styled.div`
    padding: 20px 0px;
    font-size: 18px;
    font-style: italic; 
`
const Image = styled.img`
    width: 100%;
    border: 1px gainsboro solid;
`

function Portfolio() {


    return (
        <Container >
            <div>
                <DefaultHeader title="PORTFOLIO" />
                <Introduction
                    text="Section Under Construction"
                />

                <WorkArea>
                    <WorkAreaLimit>

                    <PortfolioCard
                     title="Help Desk System" 
                     path="portfolio-dexter"
                     image="/images/dexter/dexterbase.png"
                     stretch_image={false}
                     carousel_image="/images/dexter/ticketScreen.png"
                     />
                     <PortfolioCard
                     title="SCADA" 
                     path="portfolio-scada"
                     image="/images/world.jpg"
                     stretch_image={false}
                     carousel_image="/images/tablet2.png"
                     />
                     <PortfolioCard
                     title="Mechanical Design" 
                     path="portfolio-mechanical-design"
                     image="/images/Mechanical.jpg"
                     stretch_image={false}
                     carousel_image="/images/d1.jpg"
                     />

                    <PortfolioCard
                     title="Academic Project: Automated Hydroponic System" 
                     path="portfolio-academic-projects"
                     image="/images/education.jpg"
                     stretch_image={false}
                     carousel_image="/images/SHA2.jpg"
                     />
                    
                    </WorkAreaLimit>
                </WorkArea>
            </div>
        </Container>
    );
}

export default Portfolio;