import React from 'react';
import styled from 'styled-components';


const Container = styled.div`
    width: 100%;
    padding: 20px 10px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    
`
const Icon = styled.div`
    width: 32px;
    height: 32px;
    background-color: #CFE3FE;
    border-radius: 16px;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #3884F5;
`
const Title = styled.div`
    color: #424242;
    font-weight: bold;
`
const Details = styled.div`
    color: #5f6d83;
    font-size: 12px;
`

function ContactData(props) {
    return (
        <Container >
            <Icon>
                <span className={props.icon} />
            </Icon>
            <div>
                <Title>
                    {props.title}
                </Title>
                <Details>
                    {props.details}
                </Details>
            </div>
        </Container>
    );
}

export default ContactData;