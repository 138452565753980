import styled from 'styled-components'
import { useState } from "react";
import React from 'react';
import { ReactDOM } from 'react';

const Container = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 30px;
    background-color: ${props => props.active ? "#2E6FD1" : "none"};
    color:  ${props => props.active ? "#FFFFFF" : "#424242"};
    cursor: pointer;
    display: flex;
    justify-content: initial;
    align-items: center;
    transition-duration: 0.2s;
    overflow: hidden;

`
const Icon = styled.i`
    width: 50px;
    font-size: 20px;
    text-align: center;
`




function BurgerButton(props) {
    return (
        <Container active={props.active} >
            <Icon className={props.icon} />
        </Container>
    );
}

export default BurgerButton;