import React from 'react';
import styled from 'styled-components';
import DefaultHeader from '../components/headers/DefaultHeader';
import Introduction from '../components/others/Introduction';
import HorizontalTimeline from '../components/others/HorizontalTimeline';
import VerticalTimeline from '../components/others/VerticalTimeline';


const Container = styled.div`
    width: 100%;
    min-height: 100vh;
    background-image: url("images/education.jpg");
    background-size: cover;
    background-position-y: bottom;
    background-position-x: center;
`
const ContainerBox = styled.div`
    width: 100%;
    min-height: 100vh;
    padding: 0px 25px 25px 110px;
    box-sizing: border-box;
    position: relative;
    @media only screen and (max-width:1024px){
        padding: 0px 50px 50px 50px;
    }
    @media only screen and (max-width:768px){
        padding: 0px 10px 50px 10px;
    }

`
const Glass = styled.div`
    width: 100%;
    height: 100vh;
    background-color: white;
    opacity: .85;
    position: absolute;
`
const WorkArea = styled.div`
    width: calc(100% - 0px);
    height: calc(100vh - 164px);
    display: flex;
    justify-content: center;
    align-items: center; 
    @media only screen and (max-width:1024px){
        width: 100%;
        height: calc(100vh - 189px);
    }
    @media only screen and (max-width:768px){
        width: 100%;
    }
`
const HorizontalTimelineContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    @media only screen and (max-width:666px){
        width: 100%;
        display: none;
    }
`
const VerticalTimelineContainer = styled.div`
    display: none;
    width: 100%;
    height: 100%;
    @media only screen and (max-width:666px){
        display: block;
    }
`

function Education() {
    return (
        <Container >
            <Glass />
            <ContainerBox>
                <div>
                    <DefaultHeader title="EDUCATION" />
                    <Introduction
                        text=""
                    />
                </div>
                <WorkArea>
                    <HorizontalTimelineContainer>
                        <HorizontalTimeline />
                    </HorizontalTimelineContainer>
                    <VerticalTimelineContainer>
                        <VerticalTimeline />
                    </VerticalTimelineContainer>
                </WorkArea>
            </ContainerBox>
        </Container>
    );
}

export default Education;