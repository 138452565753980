import React from 'react';
import styled from 'styled-components';
import ReactPlayer from 'react-player';
import DetailInput from '../others/DetailInput';
import DefaulFooter from '../../views/DefaulFooter';



const Container = styled.div`
    width: 100%;
    min-height: 100vh;
    box-sizing: border-box;
`
const HeaderContainer = styled.div`
    width: 100%;
    color: white;
    background-color: #f3f3f3;
    background-image: url("../images/Mechanical.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    display: flex;
    @media only screen and (max-width:1024px){
        flex-direction:column;
    }
`
const HeaderDetails = styled.div`
    width: 50%;
    padding: 120px;
    box-sizing: border-box;
    background-color: #0000009c;
    @media only screen and (max-width:1024px){
        width: 100%;
        padding: 50px;
    }
`
const HeaderImage = styled.div`
    width: 50%;
    padding: 120px 0px 0px 0px;
    box-sizing: border-box;
    background-color: #0000009c;
    overflow: hidden;
    min-height: 600px;
    @media only screen and (max-width:1024px){
        width: 100vw;
        padding: 0px;
        min-height: 0px;
    }
`
const Monitor = styled.div`
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("../images/sw_certificate.png");
    background-position: top;
    background-position: right;
    @media only screen and (max-width:1024px){
        width: 100%;
        background-position: left;
        background-position: bottom;
        height: 250px;
        padding: 0px;
    }
`

const HeaderTitle = styled.div`
    width: 100%;
    color: white;
    font-size: 60px;
    font-weight: bold;
`
const HeaderDescription = styled.div`
    padding-top: 40px;
    width: 100%;
    color: white;
    font-size: 20px;
    font-weight: bold;
    text-align: justify;
`
const CloseContainer = styled.div`
    padding: 10px 20px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    position: absolute;
`
const CloseButton = styled.a`
    //width: 40px;
    //height: 40px;
    padding: 5px 10px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: #ffffff;
    border-radius: 60px;
    cursor: pointer;
    border: 1px solid #ffffff;
    text-decoration: none;
`
const Logo = styled.div`
    font-size: 20px;
    color: #ffffff;
    padding-right: 10px;
`
const HomeButton = styled.div`
    font-size: 20px;
    color: #ffffff;
    padding-right: 10px;
    font-weight: bold;
`
const WorkArea = styled.div`
    @media only screen and (max-width:768px){
        flex-direction: column;
    }
`
const StepContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    @media only screen and (max-width:768px){
        flex-direction: column;
        padding: 20px;
        box-sizing: border-box;
    }
`
const StepContainer2 = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    @media only screen and (max-width:768px){
        flex-direction: column-reverse;
        padding: 20px;
        box-sizing: border-box;
    }
`
const StepBox = styled.div`
    width: 50%;
    margin: 50px;
    box-sizing: border-box;
    @media only screen and (max-width:768px){
        width: 100%; 
    }
    
`
const StepTitle = styled.div`
    font-size: 25px;
    font-weight: bold;
    color: #424242;
    text-align: center;
`

const StepDetails = styled.div`
    padding-top: 40px;
    width: 100%;
`
const StepVideo = styled.div`
    position: relative;
`
const WallVideo = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2;
`
const Emp = styled.span`
    color: #3884F5;
    font-weight: 550;
`
const SectionSeparator = styled.div`
    width:100%;
    height: 3px;
    border-top: 2px dashed gray;
    
`

function MechanicalDesign() {
    return (
        <Container >
            <HeaderContainer>
                <CloseContainer>
                    <CloseButton href="javascript:history.back()" >
                        <Logo className='fas fa-arrow-left' />
                        <HomeButton>
                            HOME
                        </HomeButton>
                    </CloseButton>
                </CloseContainer>
                <HeaderDetails>
                    <HeaderTitle>
                        Mechanical Design
                    </HeaderTitle>
                    <HeaderDescription>
                    Mechanical design is the creation process that is carried out before manufacturing a part or device where different aspects such as the type of material, dimensions or functionality are taken into account.
                    </HeaderDescription>
                </HeaderDetails>
                <HeaderImage>
                    <Monitor />
                </HeaderImage>
            </HeaderContainer>
            <WorkArea>
                <StepContainer>
                    <StepBox>
                        <StepTitle>
                            Casting Mold
                        </StepTitle>
                    </StepBox>
                    <StepBox>
                        <StepDetails>
                            <StepVideo>
                                <WallVideo />
                                <ReactPlayer
                                    url='videos/design/design2.mp4'
                                    controls
                                    loop
                                    playing
                                    muted
                                    width='100%'
                                    height='700px'
                                />
                            </StepVideo>
                        </StepDetails>
                    </StepBox>
                </StepContainer>
                <SectionSeparator />
                <StepContainer2>
                    <StepBox>
                        <StepDetails>
                            <StepVideo>
                            <WallVideo />
                                <ReactPlayer
                                    url='videos/design/design1.mp4'
                                    controls
                                    loop
                                    playing
                                    muted
                                    width='100%'
                                    height='700px'
                                />
                            </StepVideo>
                        </StepDetails>
                    </StepBox>
                    <StepBox>
                        <StepTitle>
                            Dryer
                        </StepTitle>
                    </StepBox>
                </StepContainer2>
                <SectionSeparator />
                <StepContainer>
                    <StepBox>
                        <StepTitle>
                            Presition Movment Fixture
                        </StepTitle>
                    </StepBox>
                    <StepBox>
                        <StepDetails>
                            <StepVideo>
                            <WallVideo />
                                <ReactPlayer
                                    url='videos/design/design3.mp4'
                                    controls
                                    loop
                                    playing
                                    muted
                                    width='100%'
                                    height='100%'
                                />
                            </StepVideo>
                        </StepDetails>
                    </StepBox>
                </StepContainer>
                <SectionSeparator />
                <StepContainer2>
                    <StepBox>
                        <StepDetails>
                            <StepVideo>
                            <WallVideo />
                                <ReactPlayer
                                    url='videos/design/design4.mp4'
                                    controls
                                    loop
                                    playing
                                    muted
                                    width='100%'
                                    height='100%'
                                />
                            </StepVideo>
                        </StepDetails>
                    </StepBox>
                    <StepBox>
                        <StepTitle>
                            Electronic Circuit
                        </StepTitle>
                    </StepBox>
                </StepContainer2>

            </WorkArea>
            <DefaulFooter />
        </Container>
    );
}

export default MechanicalDesign;