import React from 'react';
import styled from 'styled-components';


const Container = styled.div`
    width: 100%;
    text-align: center;
`


function Introduction(props) {
    return (
        <Container >
            {props.text}
        </Container>
    );
}

export default Introduction;