import React from 'react';
import styled from 'styled-components';
import DefaultHeader from '../components/headers/DefaultHeader';
import Introduction from '../components/others/Introduction';
import ContactData from '../components/others/ContactData';
import DefaultButton from '../components/buttons/DefaultButton';


const Container = styled.div`
    width: 100%;
    padding: 25px 25px 25px 110px;
    box-sizing: border-box;
    background-color: #EAEAEA;
    color: #424242;
    @media only screen and (max-width:1024px){
        padding: 25px 50px 25px 50px;
    }
    @media only screen and (max-width:768px){
        padding: 25px 10px 25px 10px;
    }
    
`
const Name = styled.div`
    width: 100%;
    font-size: 32px;
    font-weight: bold;
    color: #424242;
    text-align: center;
`
const Details = styled.div`
    width: 100%;
    color: #424242;
    text-align: center;
`
const Network = styled.div`
    width: 100%;
    text-align: center;
    font-size: 12px;
`
const Copyright = styled.div`
    width: 100%;
`
const Emp = styled.span`
    width: 100%;
    font-weight: bold;
`
const IconsContainer = styled.span`
    width: 100%;
    display: flex;
    justify-content: center;
`
const Icon = styled.a`
    text-decoration: none;
    color: #424242;
    font-size: 25px;
    margin: 10px 10px;
`
function DefaulFooter() {
    return (
        <Container >
            <Name>
                Juan Campos
            </Name>
            <Details>

            </Details>
            <Network>
                <Emp> Portfolio</Emp>
            </Network>
            <IconsContainer>
                <Icon className='fab fa-linkedin' href="https://www.linkedin.com/in/juan-alfonso-campos-servin" target="_blank" />
                <Icon className='fas fa-phone-square'  href="tel:+528991387668" />
                <Icon className='fas fa-envelope'  href="mailto:juancamposservin@outlook.com?Subject=Portfolio%20web" />
            </IconsContainer>
        </Container>
    );
}

export default DefaulFooter;