import React from 'react';
import styled from 'styled-components';
import Percent from '../components/others/Percent';
import Skill from '../components/others/Skill';
import DefaultHeader from '../components/headers/DefaultHeader';
import Introduction from '../components/others/Introduction';


const Container = styled.div`
    width: 100%;
    min-height: 100vh;
    padding: 0px 25px 25px 110px;
    box-sizing: border-box;
    @media only screen and (max-width:1024px){
        padding: 0px 50px 50px 50px;
    }
    @media only screen and (max-width:768px){
        padding: 0px 10px 50px 10px;
    }
`

const PersonalInformation = styled.div`
    width: 100%;
    padding: 60px 0px;
    box-sizing: border-box;
    display: flex;
    @media only screen and (max-width:1024px){
        flex-direction: column;
        align-items: center;
    }

`
const Photo = styled.div`
    width: 350px;
    height: 350px;
    background-image: url('images/juan.jpg');
    background-size: cover;
`
const DetailsContainer = styled.div`
    width: calc(100% - 360px);
    padding: 0 0 0 20px;
    @media only screen and (max-width:1024px){
        width: 100%;
        padding: 0px;
    }
`

const DetailsTitle = styled.div`
    width: 100%;
    padding: 0 0 20px 20px;
    font-size: 25px;
    font-weight: bold;
    color:#5f6d83;
    @media only screen and (max-width:1024px){
        padding: 60px 0 20px 0;
        text-align: center;
    }
`
const DetailsContainerSkills = styled.div`
    width: 100%;
`
const DetailsBox = styled.div`
    display: flex;
    @media only screen and (max-width:1024px){ 
        flex-direction: column;
    }
`
const Details = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    @media only screen and (max-width:1024px){
        width: 100%;   
    }
`

const SkillSubSectionContainer = styled.div`
    width: 100%;
    padding:10px;
    box-sizing: border-box;
`
const SkillSubSection = styled.div`
    width: 100%;
    margin-bottom: 20px;
    -webkit-box-shadow: 0px 0px 1px 0px rgba(0,0,0,0.75);
-moz-box-shadow: 0px 0px 1px 0px rgba(0,0,0,0.75);
box-shadow: 0px 0px 1px 0px rgba(0,0,0,0.75);
`
const SkillSubSectionText = styled.div`
    color: #3884F5;
    font-weight: bold;
    padding: 20px;
    box-sizing: border-box;
`


function Skills() {
    return (
        <Container >
            <div>
                    <DefaultHeader title="SKILLS" />
                    <Introduction
                        text=""
                    />
                    <PersonalInformation  >
                        <DetailsContainerSkills>
                            <DetailsBox>
                                <Details>
                                    <SkillSubSectionContainer>
                                        <SkillSubSection >
                                            <SkillSubSectionText>
                                                Programming
                                            </SkillSubSectionText>
                                            <Skill
                                                text="Use of different programming languages"
                                                details="Ladder, C, C++, C#, Visual Basic & G (LabVIEW)"
                                            />
                                            <Skill
                                                text="Intermediate-advanced management of PLC control system"
                                                details="Omron & Allen Bradley"
                                            />
                                            <Skill
                                                text="HMI design and programming "
                                                details="Omron, Basic & C#"
                                            />
                                            <Skill
                                                text="Microcontroller programming"
                                                details="Pic16f877a"
                                            />
                                            <Skill
                                                text="Database management"
                                                details="MySQL, SQL Server & MongoDB"
                                            />
                                            <Skill
                                                text="Web development"
                                                details="html, css, Vanilla JavaScript , MERN Stack (MongoDB, Express, React & Node JS) & LAMP Stack (Linux, Apache, MySql & PHP)"
                                            />
                                            <Skill
                                                text="Knowledge in HMI/SCADA system"
                                                details="iFix"
                                            />
                                            <Skill
                                                text="Using Osisoft Pi Software"
                                                details=""
                                            />
                                            <Skill
                                                text="Use of Power BI software"
                                                details=""
                                            />
                                        </SkillSubSection>
                                    </SkillSubSectionContainer>
                                </Details>
                                <Details>
                                    <SkillSubSectionContainer>
                                        <SkillSubSection >
                                            <SkillSubSectionText>
                                                CAD Engineering
                                            </SkillSubSectionText>
                                            <Skill
                                                text="Knowledge in mechanical design."
                                                details=""
                                            />
                                            <Skill
                                                text="Intermediate-advanced handling of SolidWorks software"
                                                details=""
                                            />
                                        </SkillSubSection>

                                        <SkillSubSection >
                                            <SkillSubSectionText>
                                                Soft skills
                                            </SkillSubSectionText>
                                            <Skill
                                                text="Adaptability, communication skills, teamwork, proactive and creative"
                                                details=""
                                            />
                                            <Skill
                                                text="Ability to lead teams for the optimization and fulfillment of projects"
                                                details=""
                                            />
                                        </SkillSubSection>

                                        <SkillSubSection >
                                            <SkillSubSectionText>
                                                Languages
                                            </SkillSubSectionText>
                                            <Skill
                                                text="Spanish - Native"
                                                details=""
                                            />
                                            <Skill
                                                text="English - Basic"
                                                details=""
                                            />
                                        </SkillSubSection>
                                    </SkillSubSectionContainer>

                                </Details>
                            </DetailsBox>
                        </DetailsContainerSkills>
                    </PersonalInformation>
                </div>
        </Container>
    );
}

export default Skills;