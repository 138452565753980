import React from 'react';
import styled from 'styled-components';
import EducationCard from '../cards/EducationCard';

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
`
const LineContainer = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
`
const Line = styled.div`
    height: calc(100% - 140px);
    width: 2px;
    background-color: #3884F5;
`
const PointContainer = styled.div`
    height: calc(100% - 125px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: -7.5px;
`

const Point = styled.div`
    width: 15px;
    height: 15px;
    border-radius: 10px;
    background-color: #3884F5; 
`

const CardsContainer = styled.div` 
    height: 100%;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between; 
    margin-left: 35px;
    margin-top: -18px;
`

function VerticalTimeline() {
    return (
        <Container >
            <LineContainer>
                <Line />
                <PointContainer>
                    <Point />
                    <Point />
                    <Point />
                </PointContainer>

            </LineContainer>

            <CardsContainer>

                <EducationCard
                    career="Technician in industrial electromechanics"
                    school="Col. Nacional de Educación Profesional
                    Técnica.
                    "
                    date="2009-2012"
                    showPosition={3}
                />


                <EducationCard
                    career="Higher University Technician in Mechatronics Automation area"
                    school="Universidad Tecnológica de Tamaulipas Norte"
                    date="2017-2019"
                    showPosition={3}
                />

                <EducationCard
                    career="Bachelor's Degree in Mechatronic Engineering"
                    school="Universidad Tecnológica de Tamaulipas Norte."
                    date="2019-2021"
                    showPosition={3}
                />

            </CardsContainer>


        </Container>
    );
}

export default VerticalTimeline;