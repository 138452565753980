import React from 'react';
import styled from 'styled-components';


const Container = styled.div`
    border-left: 2px solid #3884F5;
    padding: 10px;
    box-sizing: border-box;
`
const Circle = styled.div`
    width: 15px;
    height: 15px;
    border-radius: 15px;
    background-color: white;
    border: 2px solid #3884F5;
    position: absolute;
    margin-left: -20px;
    margin-top: -20px;
`
const Header = styled.div`
`
const Title = styled.div`
    font-weight: bold;
`
const Company = styled.span`
    color:#5f6d83;
    font-weight: bold;
    font-size: 12px;
`
const Date = styled.span`
    color: #3884F5;
    font-weight: bold;
    font-size: 12px;
`

const Activities = styled.div`
    font-size: 12px;
`

function Work(props) {
    return (
        <Container >
            <Circle />
            <Header>
                <Company><span className='fas fa-map-marker-alt' /> {props.company}</Company>
                /
                <Date><span className='far fa-calendar-alt' /> {props.date}</Date>
            </Header>
            <Title>
                {props.title}
            </Title>
            <Activities>
                {props.activities}
            </Activities>
            
        </Container>
    );
}

export default Work;