import styled from 'styled-components'
import { useState } from "react";
import React from 'react';
import { ReactDOM } from 'react';

const Container = styled.div`
    width: 50px;
    height: 50px;
    border-radius: 30px;
    background-color:#3884F5;
    color:  ${props => props.active ? "#FFFFFF" : "#424242"};
    cursor: pointer;
    display: ${props => props.active ? "flex" : "none"};
    justify-content: initial;
    align-items: center;
    transition-duration: 0.2s;
    overflow: hidden;
    &:hover{
        background-color: ${props => props.active ? "#2E6FD1" : "#DDDDDD"};
  }
  @media only screen and (max-width:1024px){
    width: 40px;
    height: 40px;
    }

  &:hover span{
        visibility: visible;
        opacity: 1;
  }

`
const Icon = styled.i`
    width: 50px;
    font-size: 20px;
    text-align: center;
`

function UpButton(props) {
    return (
        <Container active={props.active} >
            <Icon className={props.icon} />
        </Container>
    );
}

export default UpButton;